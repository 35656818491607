@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --color-primary: #3498db;
    --color-white: #ffff;
    --color-grey-100: #f5f5f5;
    --color-grey-200: #e0e0e0;
    --color-grey-300: #bdbdbd;
    --color-grey-400: #9e9e9e;
    --color-grey-500: #757575;
    --color-grey-600: #5e5e5e;
    --color-grey-700: #464646;
    --color-grey-800: #333333;
    --color-grey-900: #1a1a1a;
    --color-bg: hwb(240 95% 0%);
    --transition: all 300ms ease;

    --container-width-lg: 84%;
    --container-width-md: 98%;
    --form-width: 50%;

    --radius-1: 4px;
    --radius-2: 8px;
    --radius-3: 12px;
    --radius-4: 16px;

    
  }

  *{
    margin: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    border-style: none;
  }

body {

    font-family: "poppins", sans-serif;
    background-color: var(--color-bg);
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--color-grey-700)
}

a {
    color: var(--color-grey-700);
    text-decoration: none;
}

ul {
    list-style: none;

}

h1, h2, h3, h4, h5 {
    color: var(--color-grey-900);
    line-height: 1.1;

}

h1 {
    font-size: 2.2rem;
}
h2 {
    font-size: 1.9rem;
}
h3 {
    font-size: 1.3rem;
    line-height: 1.25;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    line-height: 1.4;
}

section {
    margin-top: 6rem;
}

.container {
    width: var(--container-width-lg);
    margin-inline: auto;

}

.btn {
    display: inline-block;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: var(--radius-2);
    background: var(--color-white);
    font-weight: 500;
    cursor:pointer;
    transition: var(--transition);
}

.btn.primary {
    background: var(--color-primary);
    color: var(--color-white);
}

.btn.category {
    background: var(--color-bg);
    color: var(--color-primary);
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.5rem;
}

.btn.sm {
    font-size: 0.8rem;
    padding: 0.3rem 0.7rem;
    font-weight: 400;

}

.btn.danger {
    background: red;
    color: var(--color-white)}

.btn:hover {
    background: var(--color-grey-700);
    color: var(--color-white);
}

.center {
    text-align: center;
    padding-top: 5rem;
    margin-inline: center;
    display: block;
    width: 100%;
}

.error-page h2 {
    margin-top: 3rem;
}

nav {
    width: 100vw;
    height: 6rem;
    display: grid;
    place-items: Justified;
    background: var(255, 255, 255, 0.5);
   padding: 0 3rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 4px solid var(--color-bg);
    backdrop-filter: blur(20px);

}

.nav__site {
    font-weight: 600;
}

.nav__site-name {
    color: rgb(59 130 246 / 1);
    letter-spacing: -0.025em;
    

}

.nav__logo-name {
   display: flex;
   align-items: center;
   gap: 0.5rem;
   margin-top: 0.5rem;
   }

.nav__logo-name h1 {

    font-size: 2.5rem;
}

footer {
    background: var(--color-grey-900);
    margin-top: 6rem;
    padding-top: 6rem;
    
}

 .footer__categories {    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
    margin-bottom: 4rem;
}


.footer__categories a {

    background: var(--color-grey-700);
    color: var(--color-white);
    padding: 0.3rem 1rem;
    border-radius: var(--radius-1);
    align-items: center;
    justify-content: center;
    transition: var(--transition);
}

.footer__categories a:hover {
    background: var(--color-white);
    color: var(--color-grey-900);
}

.footer__copyright {
    text-align: center;
    border-top: 2px solid var(--color-grey-700);
    background: var(--color-grey-700);
    padding: 1rem;
    color: var(--color-white);
    font-weight: 300;
}

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.nav__toggle-btn {
    display: none;
}

.nav__logo {
    width: 4rem;
    display: block;
}

.nav__menu {
    display: flex;
    align-items: center;
    gap: 3rem;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

/* home------ */

.post__container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;

}

.post {
    background: var(--color-white);
    padding: 1rem;
    border-radius: var(--radius-4);
    padding-bottom: 2rem;
    transition: var(--transition);
    cursor: default;

}

.post:hover{
    box-shadow: 0 1.7rem 3rem rgba(0,0,0,0.2);
}
.post__thumbnail {
    border-radius: var(--radius-3);
    overflow: hidden;
    height: 20rem

}

.post__content {
    margin-top: 1.5rem;
    }
.post__content h3 {
    margin: 1rem 0 0.6rem;
}

.post__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    scroll-margin-top: 2rem;
}

.post__author {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.post__author-avatar {

    width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--radius-4);
    overflow:hidden;

}
    /*------post detail */

    .post-detail {
        width: var(--form-width);
        background: var(--color-white);
        margin-inline: auto;
        padding: 2rem ;

    }

    .post-detail__header {
        display: flex;
        justify-content: space-between;
        margin-bottom:1.5rem;     

    }

    .post-detail__buttons {
        display:flex;
        gap: 1rem;
        align-items: center;
    }

    .post-detail__thumbnail {
        margin: 1rem 0;
        height: fit-content;
        max-height: 50rem;
        overflow: hidden;
    }

    .post-detail p {
        margin-bottom: 0.7rem;
    }

    /*=================registration form====== */

    .form {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    input, select, textarea {
        width: 100%;
        padding: 0.6rem 1rem;
        border-radius: var(--radius-1);
        resize:none;
    }

    .form__error-message {
        background: red;
        color: var(--color-white);
        font-size: 0.8rem;
        padding: 0.6rem 1rem;
        border-radius: var(--radius-1);
        display:block;
        margin-bottom: 1rem;    
    }

    .register, .login, .create-post {
        width: var(--form-width);
        margin-inline: auto;
        height: calc(100vh - 12rem);

    }

    .register h2, .login h2, .create-post h2 {
        margin-bottom: 2rem;

    }

    .register small, .login small {
        display: block;
        margin-top: 1.5rem;
    }

    .register small a, .login small a {
        color: var(--color-primary);
    }

    .ql_editor {
        overflow: scroll;
        background: var(--color-white);
        height: 10rem !important;
    }


    /*=======================authors=========*/

    .authors__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

    }

    .author {
        background: var(--color-white);
        padding: 1rem;
        border-radius: var(--radius-3);
        display: flex;
        gap: 1rem;
    }

    .author:hover {
        box-shadow: 0 1rem 1.5rem rgba(0,0,0,0.7);
    }
    
    .author__avatar {
        width: 8rem;
        aspect-ratio: 1/1;
        border: 0.3rem solid var(--color-bg);


    }

    /*==================user prifile================*/

    .profile__container {
        display: grid;
        place-items: center;
        text-align: center;
    }

    .profile__details {
        width: 100%;
    }

    .avatar__wrapper {
        width: 15rem;
        aspect-ratio: 1/1;
        position: relative;
        margin-inline: auto;
        margin-top: 1rem;
    }
    .profile__avatar {
        height: 100;
        border-radius: 50%;
        border: 1rem solid var(--color-white);
        overflow: hidden;
    }

    .avatar__form {
        height: 1rem;
    }

    .avatar__form input {
        visibility: hidden;
    }

    .avatar__form label, .profile__avatar-btn {
        background: var(--color-grey-900);
        color: var(--color-white);
        font-size: 1.2rem;
        position:absolute;
        right: 2rem;
        bottom:1.4rem;
        width: 3rem;
        height: 3rem;
        display: grid;
        place-items: center;
        border-radius: 50%;
    }

    .profile__avatar-btn {
        cursor: pointer;
        padding: 0;
        font-size: 1.5rem;
        background: var(--color-primary);        
    }

    .profile__details h1 {
        margin-top: 1.5rem;
    }

    .form.profile__form {
        margin-top: 3rem;
        margin-inline: auto;
        width: var(--form-width);
    }


    /*======================dashboard*/

    .dashboard__container {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .dashboard__post{
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        background: var(--color-white);
        padding: 0.5rem 1.2rem;
        border-radius: var(--radius-3);
    }

    .dashboard__post-info {
        display: flex;
        align-items: center;
        gap: 2rem;
        width: 100%;
    }

    .dashboard__post-thumbnail {
        width: 4rem;
        border-radius: var(--radius-1);
        overflow: hidden;

    }

    .dashboard__post-actions {
        display: flex;
        gap: 1rem;
    }

    /*=====================loader ====================*/

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: grid;
        place-items: center;
        background: var(--color-bg);
    }

    .loader__image {
        width: 4.5rem;
        aspect-ratio: 1/1;

    }


    /*===================Mobile phones ==================================*/


    @media screen and (max-width: 800px) { 
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.6rem;
        }
        h3 {
            font-size: 1.35rem;
        }
        h4 {
            font-size: 1.2rem;
        }

        .container {
            width: var(--container-width-md);
        }

        .nav__container {
            position: relative;
        }

        .nav__center {
            position: absolute;
            inset: 0;
            margin: auto;
        }
        .nav__menu {
            position: absolute;
            top: 5rem;
            right: 0;
            flex-direction: column;
            gap: 0;
        }

        .nav__menu li {

            width: 100%;
            box-shadow: -2rem, 4rem, 4rem, rgba(0,0,0,0.26);

        }

        .nav__menu li a {
            display: block;
            background: var(--color-white);
            padding: 0.85rem 2.5rem;
        }

        .nav__toggle-btn {
            display: inline-block;
            background: transparent;
            font-size: 1.5rem;
        }

        .post__container {
            grid-template-columns: 1fr;
            gap: 1rem;
        }

        .nav__logo-name {
            justify-content:center;

            }
         
         .nav__logo-name h1 {
         
             font-size: 2rem;
         }

         .footer__categories {
            flex-direction: column;
         }

         .post-detail {
            width: 100%;
            background: transparent;
            padding: .5rem;
         }

         .register, .login, .create-post {
            width: 100%;
         }

         .post-detail h1 {
            margin-top: 1rem;
         }

         .form.profile__form {
            margin-top: 1rem;
            width: 100%;
         }
         .authors__container {
            grid-template-columns: 1fr;
            gap:1.2rem;
         }

      .dashboard__post {
        flex-direction: column;
      }

      .dashboard__post-info {
        gap: 1.5rem;
      }

      .dashboard__post-thumbnail {
        width: 5rem;
      }

nav {
    padding: 0.2rem;
}

.nav__logo-name {
    text-align: center;
}

    }

